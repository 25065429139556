.category-container {
    border: 2px solid #afacac;
    border-radius: 5px;
    padding-bottom: 20px;
}

.menu-html-container {
    border: 2px solid #afacac;
    border-radius: 5px;
    padding-bottom: 20px;
}

.category-id-from {

    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
}



.category-select {
    width: 100%;

}

.mapping-btn {

    width: 100%;
    display: flex;
    justify-content: center;
}

.mapping-btn Button {

    text-wrap: nowrap;
}

.category-close-btn {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    cursor: pointer;
}

li.menu_folder a {
    text-decoration: none;
    white-space: nowrap;
}


li.menu_leaf a {
    width: 100%;

    text-decoration: none;
    white-space: nowrap;
}

li.menu_leaf.mapped a {
    color: #fd450d !important;
    text-decoration: none;

}

li.menu_leaf .mapped-count {
    color: black;
}

.progress-bar {
    font-size: 8px;
}

.mapping-count {

    display: flex;
    white-space: nowrap;
    font-size: 12px;
}

.website-details-id span {
    font-weight: 700;
}

.website-details-id a {
    text-decoration: none;
    color: inherit;
}


.mapped-screen-details {
    padding: 20px 0px;
}

.mapped-screen-data {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.mapped-screen-title {
    font-size: 16px !important;

    font-weight: 600;
}
.mapped-screen-name{
    font-size: 14px !important;
}

.mapped-screen-tipdata{
    font-size: 12px !important;
}