.global-category-heading {



    &-addBtn {
        padding: 5px 9px;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        gap: 10px;

        p {
            padding-top: 10px;
            font-weight: bold;
            font-size: 15px;
        }
    }

    &-btn {
        display: flex;
        align-items: center;
        border-radius: 50%;
        padding: 10px;
        background-color: #AA0F0C;
        border-color: none !important;

        &:hover {
            background-color: black;


        }

    }
}

.add-category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-form {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border: none !important;
        width: 65%;
    }
}



.update-category-form {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none !important;
    width: 65%;
}


.category-details-id {

    display: flex;
    flex-direction: column;

    white-space: nowrap;

    .global-cat-details {
        font-size: 13px !important;
    }

    span {
        font-size: 12px !important;
    }

    li {
        font-size: 12px !important;
    }
}

.category-preview {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e0e3e7;
    margin-bottom: 15px !important;
    padding-bottom: 15px;

    &-container {
        // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: #e9e9e9;
        border-radius: 6px;


        img {
            width: 50px;
            height: 50px;
        }
    }

    &-bannerBtn {
        border-color: #d5d5d5 !important;
        width: 20px !important;
        height: 20px !important;
        background-color: white !important;
        color: black !important;
        border-radius: 50% !important;
        padding: 12px !important;
        right: 0;
        left: 95%;
        top: -10px;

    }


    &-tileBtn {
        border-color: #d5d5d5 !important;
        width: 20px !important;
        height: 20px !important;
        background-color: white !important;
        color: black !important;
        border-radius: 50% !important;
        padding: 12px !important;
        right: 0;
        left: 86%;
        top: -10px;

    }

    &-newTileBtn {
        border-color: #d5d5d5 !important;
        width: 20px !important;
        height: 20px !important;
        background-color: white !important;
        color: black !important;
        border-radius: 50% !important;
        padding: 12px !important;
        right: 0;
        left: 90%;
        top: -10px;
    }

    &-iconBtn {
        border-color: #d5d5d5 !important;
        width: 20px !important;
        height: 20px !important;
        background-color: white !important;
        color: black !important;
        border-radius: 50% !important;
        padding: 12px !important;
        right: 0;
        left: 85%;
        top: -12px;
    }
}


.banner-category {


    &-container {
        // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: #e9e9e9;
        border-radius: 6px;
        margin: 15px 0px;


    }


    &-tileBanner {
        // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 40%;
        background-color: #e9e9e9;
        border-radius: 6px;
        margin: 15px 0px;
    }

    &-newTileBanner {

        width: 40%;
        background-color: #e9e9e9;
        border-radius: 6px;
        margin: 15px 0px;

        img {
            width: 100%;
        }
    }

    &-blankTileBanner {
        // border: 2px solid red;
        overflow: hidden;
    }
}

.update-product-btn {
    background-color: green !important;
}


.global-category-action {
    color: black !important;
}


.update-category-details-container {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 35%;
    background-color: #fffcf2;
}

.update-category-detail {
    line-height: 1.5;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e3e7;
}

.update-category-detail span:nth-child(1) {
    color: #999;
    font-size: 10px;
    line-height: normal;


}

.update-category-detail span:nth-child(2) {
    font-size: 16px;
    font-weight: bold;
}

.update-category-detail span:nth-child(3) {
    color: #373435;
    font-size: 10px;
}


.icon-class {
    font-size: 10px;
    color: #999;
}



.refresh-btn {
    padding: 12px 0 !important;
    background-color: rgb(55, 54, 54) !important;
    color: white !important;
}


.testing-banner {




    img {
        display: flex;
        flex-direction: row !important;
        width: 30px;
        height: 20px;
    }
}


.icon-delete {
    font-size: 12px;
    line-height: 0px;
}



.p-datatable .p-datatable-tbody>tr>td {
    padding: 0.3rem 1rem !important;

}


.categoryIcon-disabled {
    cursor: not-allowed;
}



.pi {
    font-size: 0.7rem !important;
}


.prime-react-btn {


    button:nth-child(2) {


        background-color: #f6aa1f !important;

    }
}

.cropped-container {
    width: 100%;
    height: 100%;

    &-image {
        flex-grow: 1;
        height: 100%;
        position: relative;
    }

    &-btn {
        text-align: center;
        margin-top: 10px;

        Button {
            background-color: #f6aa1f !important;
            border: none !important;
        }
    }
}


.form-required::after {
    content: ' *';
    color: red;
}

.form-check-input[type="checkbox"]:checked::after {
    border: 0 !important;
}


.p-calendar input {
    height: 37px;
}


.b2b-wrapper {
    font-size: 12px !important;
}

.b2b-span {
    font-size: 12px !important;
    font-weight: 600;
    color: #f6aa1f;
}


.person-class {
    padding: 2px 8px;
    border: 1px solid #f6aa1f;
    background-color: #fef5e3;
    border-radius: 50px;
    font-size: 9px !important;
    text-transform: uppercase;
}

.party-group {
    padding: 2px 8px;
    border: 1px solid #84c5e6;
    background-color: #e3f5fe;
    border-radius: 50px;
    font-size: 9px !important;
    text-transform: uppercase;
}


.show-more {
    font-size: 12px !important;
    color: #f6aa1f;
}