.divider:after,
.divider:before {
content: "";
flex: 1;
height: 1px;
background: #eee;
}
input{
    border: 1px solid lightgrey;
    border: 1px;
}