
// /* DataTableDemo.css */
@import "~react-image-gallery/styles/scss/image-gallery.scss";
.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media screen and (max-width: 960px) {
    .datatable-crud-demo .table-header {
        align-items: flex-start;
    }
}
.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
    .datatable-crud-demo .p-toolbar {
        flex-wrap: wrap;
    }
    .datatable-crud-demo .p-toolbar .p-button {
        margin-bottom: 0.25rem;
    }
    .datatable-crud-demo .table-header {
        flex-direction: column;
    }
    .datatable-crud-demo .table-header .p-input-icon-left, .datatable-crud-demo .table-header input {
        width: 100%;
    }
}        
.image-gallery-image{
    max-height: calc(100vh - 30vh);
}
.image-gallery{
    height: 100%;
    *{
        height: 100%;
    }
    .image-gallery-thumbnails-wrapper{
        height: 20%;
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translate(-50%, 0);
    }
    .image-gallery-play-button{
        position: absolute;
        left: 50%;
        top: 0%;
    }
    .image-gallery-fullscreen-button{
        display: flex;
        align-items: flex-end;
        height: auto;
    }
}
.p-column-header-content{
    font-size: 14px;
}
.field{
    label{
        font-size: 14px;
    }
}
td{
    *{
        font-size: 14px;
    }
}