body {
    background-color: white;
  }
  @media (min-width: 991.98px) {
    .menu-bar {
      animation:menu_bar_animation  0.5s;
      animation-fill-mode: forwards;
    }
    .menu_bar_out{
      animation-fill-mode: forwards;
      animation: menu_bar_out_animation;
    }
    @keyframes menu_bar_animation {
      0%{
       padding-left: 0px;
      }
      100%{
        padding-left: 240px;
      }
    }
    @keyframes menu_bar_out_animation {
      0%{
       padding-left: 240px;
      }
      100%{
        padding-left: 0px;
      }
    }
  }
  @media (min-width:991px){
  .menu-animation{
    animation: fade_in_show 0.5s;
    animation-fill-mode: forwards;
  }
  .menu-animation-out{
    animation: fade_out_show 0.5s;
    animation-fill-mode: forwards;
  }
  @keyframes fade_in_show {
    0% {
      width: 0px;
    }

    100% {
      width: 252px;
    }
}
@keyframes fade_out_show {
  0% {
    width: 252px;
  }

  100% {
    width: 0px;
  }
}
  }
  /* Sidebar */
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 58px 0 0; /* Height of navbar */
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);

    z-index: 600;
   
    overflow: auto;
    
  }
  
  .sidebar::-webkit-scrollbar {
    display: none; 
  }
  
  .sidebar {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  @media (max-width: 991.98px) {
    .sidebar {
      width: 100%;
    }
  }
  .sidebar .active {
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  }
  
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }
  .my-sidebar-menu{
    #main-navbar{
      background-color: #f1f1f1;
    }
    #sidebarMenu{
      background-color: #f1f1f1;
    }
    .list-group {
      --bs-list-group-color: #fff;
      --bs-list-group-bg: #212121;
      --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
      --bs-list-group-border-width: 1px;
      --bs-list-group-border-radius: 0.375rem;
      --bs-list-group-item-padding-x: 1rem;
      --bs-list-group-item-padding-y: 0.5rem;
      --bs-list-group-action-color: #495057;
      --bs-list-group-action-hover-color: #495057;
      --bs-list-group-action-hover-bg: #f8f9fa;
      --bs-list-group-action-active-color: #212529;
      --bs-list-group-action-active-bg: #e9ecef;
      --bs-list-group-disabled-color: #6c757d;
      --bs-list-group-disabled-bg: #fff;
      --bs-list-group-active-color: #fff;
      --bs-list-group-active-bg: #0074a2;
      --bs-list-group-active-border-color: #0074a2;
      display: flex;
      flex-direction: column;
    }
    .navbar {
      --bs-navbar-padding-x: 0;
      --bs-navbar-padding-y: 0.5rem;
      --bs-navbar-color: white;
      --bs-navbar-hover-color: white;
      --bs-navbar-disabled-color: white;
      --bs-navbar-active-color: white;
      --bs-navbar-brand-padding-y: 0.3125rem;
    --mdb-navbar-box-shadow:none;
    --bs-navbar-color:black;
  
    }
  }
  
  .nav-link{
    color: black;
  }
  #sidebarMenu{
  .ps-sidebar-root{
    border-right:  0px !important;
    margin-top: 20px;
  }
  .ps-sidebar-container{
    background-color: #f1f1f1 ;
    color: black;
  }
  .ps-menuitem-root{
    background-color: #f1f1f1 ;
  }
  .ps-menu-root{
    --bs-link-hover-color: #8cc540;
  }
  .ps-menu-button:hover{
      color: #8cc540;
      background-color: #e2eef9;
  }
  .ps-active .ps-menu-button{
    color: #6fa22c;
    background-color: #FFF;
    font-weight: 600;
  }
  a{
    color: black;
    text-decoration: none;
  }
  a:hover{
    color: #6fa22c;
    background-color: #FFF !important;
    font-weight: 600;
  }
  
  }
  .dropdown-item{
    font-size: 12px;
    margin: 0;
    padding: 10px !important;
  }
  .dropdown-menu-end{
    padding: 0 !important;
  }
  
  .userInfo{
    font-size: 12px;
    text-align: right;
  }
  .img{
    margin-left: 10px;
  }