.field{
    display: flex;
    flex-direction: column;
    label{
        font-weight: bold;
    }
}
.button-group-inquiry{
    height: 100%;
    display: flex;
    align-items: center;
}