@import "./styles/index.scss";
.container-custom{
    width: min(100%,1140px);
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width:1200px) {
        width: min(100%,776px);
    }
    @media screen and (max-width:776px) {
        width: 95%;
    }
}