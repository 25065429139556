.create-post{
    .field{
        display: flex;
        flex-direction: column;
    }
}


.image-item {
    position: relative;
    display: inline-block;
    margin-right: 5px; /* Spacing between images */
}

.btn-close {
    background: transparent;
    border: none;
    color: red; 
    font-size: 16px; 
    cursor: pointer;
}


.close-btn{
    border: 2px solid red !important;
}
