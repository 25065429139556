.carousel-demo .product-item .product-item-content {
    border: 1px solid var(--surface-d);
    border-radius: 3px;
    margin: .3rem;
    text-align: center;
    padding: 2rem 0;
}

.carousel-demo .product-item .product-image {
    width: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

    .modal-body {
        width: 100%;
    }

    .p-carousel {
        height: 100%;
    }

    .p-carousel-item {
        flex: 0 0 auto;
    }

    .p-carousel-items-content {
        max-height: 400px ;
    }
    .caption_input{
        width: 100%;
        background-color: grey;
        border-radius: 8px;
        padding: 10px;
        color: white;
        border: grey;
    }
    .caption_input::placeholder{
        color: white;
    }