.url-screen {
    .add-more-btn {
        border: 0.6px solid grey;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
    }

    .grey-color {
        color: grey;
    }

    .cross-btn {
        display: flex;
        width: auto;
        right: 0;
        top: 50%;
        transform: translate(4%, -90%);
        position: absolute;
        font-size: 20px;
        cursor: pointer;
    }
    .crossIcon:hover{
        color: gray;
    }
    .addMetaBtn:hover{
        border: 1px solid #0d6efd;
        color: #4b85f8
    }
}